@use 'sass:math';
@import '../variables.scss';

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

$brand-success: #5cb85c;
$loader-size: 100px;
$check-height: math.div($loader-size, 2);
$check-width: math.div($check-height, 2);
$check-left: math.div($loader-size, 6) + math.div($loader-size, 12);
$check-thickness: 3px;
$check-color: $brand-success;
$error-color: red;
$error-left: math.div($loader-size, 4);

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }

  20% {
    height: 0;
    width: $check-width;
    opacity: 1;
  }

  40% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }

  100% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s $cubic;
  position: relative;
  overflow: hidden;
  width: 70.72px;
  height: 70.72px;

  &.large {
    width: 70.72px;
    height: 70.72px;
  }

  &.small {
    width: 21.22px;
    height: 21.22px;
  }

  &.inline {
    display: inline-flex;
    vertical-align: text-bottom;
  }

  .checkmark {
    animation-duration: 800ms;
    animation-timing-function: ease;
    animation-name: checkmark;
    transform: scaleX(-1) rotate(135deg);
    height: $check-height;
    width: $check-width;
    transform-origin: left top;
    border-right: $check-thickness solid $check-color;
    border-top: $check-thickness solid $check-color;
    left: 7px;
    top: 35px;
    position: absolute;
  }

  .error {
    // position: absolute;
    // left: 7px;
    // top: 35px;
    width: math.div($loader-size, 2);
    height: math.div($loader-size, 2);
    // width: 50px;
    // height: 50px;

    &:before,
    &:after {
      position: absolute;
      content: ' ';
      height: math.div($loader-size, 2);
      width: 2px;
      left: 35.35px - 1px;
      background-color: #f00;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }

  .spinner {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 3px solid $blue-20;
    border-radius: 50%;
    border-top-color: $blue-80;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
  }

  &.large .spinner {
    width: 50px;
    height: 50px;
  }

  &.small .spinner {
    width: 15px;
    height: 15px;
  }

  &.white .spinner {
    border: 3px solid $white;
    border-top-color: $blue-20;
  }

  &.blue .spinner {
    border: 3px solid $blue-20;
    border-top-color: $blue-80;
  }
}

.full {
  margin: auto;
  width: 100%;
}

.container {
  // Not used by may be used since all styles are exposed
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100vh;
  width: 100vw;
  pointer-events: none;
  top: 0;
}
