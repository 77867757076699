@import '@hiredigital/ui/variables';

.hasHero {
  margin-top: -60px;
}

.avatar {
  height: 32px;
  width: 32px;
  object-fit: cover;
  border-radius: $border-radius;
}

.main {
  background-color: $primary-00;
}

.insights {
  background-color: $white;
}

.enterprise {
  background-color: $primary-dark;
}

.header {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  max-width: $content-width + 40px;

  height: 60px;
  margin: auto;
  padding: 10px 20px;

  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  position: sticky;
  top: 0;

  @include for-size(tablet-landscape-down) {
    // Mobile Menu
  }

  @include for-size(phone-only) {
    // Really Small
    max-width: 100%;
  }
}

.container {
  height: 60px;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: $z-nav;
  color: $white;

  &.isShaded {
    background-color: rgba($neutral-800, 0.2);
  }

  &.isBlack {
    background-color: $white;
    color: $black;

    .navLink {
      color: $black;
      &:hover {
        color: $primary-600;
        text-decoration: underline;
      }
    }

    .primaryButton {
      background: $primary-400;
      color: $white;
    }
  }

  &.isFixed {
    position: relative;
  }

  &.isOffset {
    margin-top: 20px;
  }

  &.isTransparent {
    background-color: transparent;
    position: absolute;
  }

  &.menuIsOpen {
    @include for-size(tablet-landscape-down) {
      background: $primary-900;
      &.isBlack {
        background: $white;
      }
    }
  }

  .mobileList {
    // border-top: 1px solid $blue-150;
    background: $primary-900;
  }

  &.isBlack .mobileList {
    background: $white;
  }
}

.wrapper {
  z-index: $z-base;
  // z-index required for IE11;
  position: relative;
  &:before {
    content: '';

    transition: 0.3s background $cubic;
  }

  .sticky &:before {
    content: '';
    width: 100%;
    height: 65px;
    top: -5px;
    left: 0;
    position: absolute;
    background-color: hsla(225, 100%, 18%, 0.8);
    backdrop-filter: blur(15px);
  }
}

.logoSuffix {
  font-size: $size-4;
  margin-top: 10px;
  font-weight: $fw-2;
  margin-left: 10px;
  line-height: $line-height-4;
  font-family: $cerebri;

  @include for-size(medium-phone-only) {
    display: none;
  }
}

.logoLink {
  color: inherit;
  // align-items: flex-start;
  display: flex;

  text-decoration: none;
  // align-items: center;
  // line-height: 0;
  // justify-content: center;
  height: 100%;
}
.logo {
  margin: 6px 0 0;

  @include for-size(phone-only) {
    max-width: 200px;
  }
}

.headerLink {
  color: inherit;
  text-decoration: none;
  margin-inline: 15px;
  &:hover {
    text-decoration: underline;
  }
}

.navLink {
  color: $white;
  font-weight: $fw-2;
  text-decoration: none;
  padding: 18px 10px;
  // border-radius: 3px;
  transition: background-color 0.1s $cubic, color 0.1s $cubic;

  &:hover {
    color: $yellow-100;
    text-decoration: underline;
  }

  .isBlack & {
    color: $black;
  }

  &.activeNavItem {
    color: $white;
    border-bottom: 2px solid $white;
    // background: $blue-120;

    .isBlack & {
      color: $primary-900;
      border-bottom: 2px solid $primary-900;
    }

    &:hover {
      color: $yellow-100;
    }
  }

  @include for-size(tablet-landscape-down, 50px) {
    padding: 10px;
  }
}

.primaryButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: $btn-padding-3;
  font-family: $cerebri;
  // min-width: 160px !important;
  border-radius: $border-radius;
  box-sizing: border-box;
  border: 2px solid transparent;
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
  user-select: none;
  white-space: nowrap;
  font-weight: $fw-2;
  font-size: $btn-font-3;
  text-decoration: none;
  overflow: hidden;
  line-height: $btn-font-3;
  transition: transform 0.2s $cubic, background-color 0.4s $cubic, box-shadow 0.3s ease-in-out,
    width 0.4s $cubic;
  will-change: background;

  background: $neutral-00;
  color: $primary-900;
  min-width: 170px;

  &:hover {
    color: $primary-600;
    background: $white;
    box-shadow: $shadow-50;
    transform: translateY(-2px);
  }
}

.abmButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 11px;
  font-family: $archia;
  // min-width: 160px !important;
  // border-radius: $border-radius;
  box-sizing: border-box;
  border: 2px solid transparent;
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
  user-select: none;
  white-space: nowrap;
  font-weight: $fw-2;
  font-size: $btn-font-3;
  text-decoration: none;
  overflow: hidden;
  line-height: 16px;
  transition: transform 0.2s $cubic, background-color 0.4s $cubic, box-shadow 0.3s ease-in-out,
    width 0.4s $cubic;
  will-change: background;

  background: $neutral-00;
  border: 1px solid $black;
  color: $black;
  min-width: 170px;

  &:hover {
    color: $primary-600;
    background: $white;
    box-shadow: $shadow-50;
    transform: translateY(-2px);
  }
}

.navItem {
  padding: 5px;
  white-space: nowrap;
  line-height: 0;

  &:last-of-type {
    padding-right: 0;
  }
}

.leftBorder {
  &:before {
    // color: $blue-120;
    content: '|';
    margin-right: 10px;
  }

  & a:hover {
    // background: $gray-5;
    transition: color 0.1s $cubic;
    // color: $white;
  }
}

.navList {
  display: flex;
  align-items: center;

  @include for-size(tablet-landscape-down) {
    display: none;
  }
}

.mobileMenu {
  display: none;

  @include for-size(tablet-landscape-down) {
    display: block;
  }
  margin-left: auto;

  // Overrides for the mobile version of the mneu
  .leftBorder {
    &:before {
      display: none;
    }
  }

  .navList {
    display: none;

    @include for-size(tablet-landscape-down) {
      display: block;
    }
  }

  .navItem {
    &:last-of-type {
      padding: 10px;
    }
  }

  .navLink {
    justify-content: center;
    align-items: center;
    display: flex;

    &.activeNavItem {
      border-bottom: none;
    }
  }

  .primaryButton {
    width: 100%;
  }
}

.mobileList {
  padding: 10px 0;
  position: absolute;
  top: 60px;
  width: 100%;
  left: 0;
}
.mobileButton {
  cursor: pointer;
}

.mobileMask {
  @include for-size(tablet-landscape-down) {
    display: block;
  }

  @include for-size(tablet-landscape-up) {
    display: none;
  }

  position: fixed;

  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: $z-base;
  background-color: rgba(0, 0, 0, 0.8);
  overflow: hidden;
}
