@import '@hiredigital/ui/variables';

.latestContainer {
  width: 100%;
  position: relative;
  height: 100%;
  display: block;
  text-decoration: none;
  background-color: $primary-900;

  &:before {
    content: '';
    // This is for adding a blue background around the header
    position: absolute;
    width: 100%;
    top: -100px;
    height: 100px;
    background-color: $primary-900;
  }
}

.firstContainer {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  cursor: pointer;
  max-width: $content-width + 40px;
  padding: 0 20px;
  margin: auto;

  @include for-size(phone-only) {
    flex-direction: column-reverse;
  }

  &:hover {
    .firstTitle {
      text-decoration: underline;
    }

    .firstMore {
      transform: translateY(-3px);

      .moreArrow {
        margin-left: 10px;
      }
    }
  }
}

.firstNote {
  text-transform: uppercase;
  font-size: 14px;
  color: $blue-10;
  font-weight: 600;
}

.firstTitle {
  color: $white;
  font-size: 40px;
  font-weight: 600;
  line-height: 1.2;
  padding: 20px 0;
  max-width: 100%;

  @include for-size(phone-only) {
    font-size: 28px;
    padding: 20px 0;
  }
}

.firstTags {
  display: flex;

  @include for-size(phone-only) {
    flex-wrap: wrap;
  }
}

.firstTag {
  color: $gray-2;
  line-height: 1.2;
  margin-right: 20px;
  white-space: nowrap;
}

.firstImageContainer {
  display: flex;
  flex-basis: 50%;
  position: relative;
  height: 350px;

  @include for-size(tablet-portrait-up) {
    margin: 40px 0px;
  }

  @include for-size(phone-only) {
    flex-basis: auto;
    height: 200px;
  }
}

.image {
  object-fit: cover;
  border-radius: $border-radius;
}

.firstContent {
  flex-basis: 50%;
  box-sizing: content-box;
  background-color: $primary-900;
  padding: 50px 20px 50px 0px;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include for-size(tablet-landscape-down) {
    padding: 30px 0;
  }

  @include for-size(phone-only) {
    padding: 30px 0;
    height: auto;
    flex-basis: auto;
  }
}

.firstMore {
  border: 1px solid $blue-5;
  display: flex;
  justify-content: center;
  padding: 10px 20px;
  max-width: 200px;
  color: $white;
  margin-top: 25px;
  position: relative;
  z-index: 0;
  border-radius: $border-radius;
  transition: transform 0.2s $cubic;
}
