/* prettier-ignore */
/* beautify ignore:start */

// Default sizing

$size-0: 10px;
$line-height-0: 22px;

$size-1: 12px;
$line-height-1: 24px;
$size-2: 14px;
$line-height-2: 24px;
$size-3: 16px;
$line-height-3: 28px;
$size-4: 18px;
$line-height-4: 28px;
$size-5: 20px;
$line-height-5: 30px;
$size-6: 24px;
$line-height-6: 30px;
$size-7: 30px;
$line-height-7: 36px;
$size-8: 36px;
$line-height-8: 42px;
$size-9: 48px;
$line-height-9: 60px;
$size-10: 60px;
$line-height-10: 60px;
$size-11: 72px;
$line-height-11: 72px;

// Button Sizes
$btn-padding-1: 6px 8px;
$btn-font-1: 12px;
$btn-padding-2: 8px 10px;
$btn-font-2: 14px;
$btn-padding-3: 12px 16px;
$btn-font-3: 16px;
$btn-padding-4: 18px 34px;
$btn-font-4: 20px;

// Font weights
$fw-1: 400;
$fw-2: 600;
$fw-3: 700;

// Default Border Radius
$br-small: 3px;
$br-large: 6px;
$border-radius: 8px;

// For "frosted glass" effect:
// Use with translucent background and (-webkit-)backdrop-filter

$frosted-glass: blur(1px);

// For white backgrounds

$white: hsla(0, 0%, 100%, 1);
$translucent-white: hsla(0, 0%, 100%, 0.9);

$primary-00: hsla(223, 100%, 96%, 1);
$primary-100: hsla(225, 100%, 89%, 1);
$primary-200: hsla(225, 100%, 75%, 1);
$primary-300: hsla(225, 100%, 67%, 1);
$primary-400: hsla(225, 100%, 58%, 1);
$primary-500: hsla(225, 100%, 50%, 1);
$primary-600: hsla(225, 99%, 44%, 1);
$primary-700: hsla(225, 100%, 34%, 1);
$primary-800: hsla(225, 100%, 25%, 1);
$primary-900: hsla(225, 100%, 18%, 1);
$primary-dark: hsla(225, 91%, 9%, 1);

$neutral-00: hsla(0, 0%, 100%, 1);
$neutral-100: hsla(210, 44%, 96%, 1);
$neutral-200: hsla(210, 22%, 87%, 1);
$neutral-300: hsla(210, 22%, 81%, 1);
$neutral-400: hsla(210, 11%, 67%, 1);
$neutral-500: hsla(210, 11%, 43%, 1);
$neutral-600: hsla(210, 11%, 28%, 1);
$neutral-700: hsla(210, 11%, 15%, 1);
$neutral-800: hsla(210, 11%, 7%, 1);

$neutral-placeholder: hsla(210, 2%, 54%, 1);

// $blue-5: hsla(210, 100%, 97%, 1);
// $blue-7: hsla(215, 94%, 93%, 1);
// $blue-10: hsla(209, 87%, 88%, 1);
// $blue-20: hsla(209, 77%, 75%, 1);
// $blue-40: hsla(211, 75%, 59%, 1);
// $blue-80: hsla(214, 85%, 46%, 1);
// $blue-100: hsla(214, 92%, 36%, 1);
// $blue-120: hsla(224, 66%, 28%, 1);
// $blue-150: hsla(224, 83%, 19%, 1);
// $blue-170: hsla(214, 100%, 10%, 1);

$light-blue-5: hsl(199, 100%, 93%, 1);

$blue-5: $primary-00;
$blue-7: $primary-100;
$blue-10: $primary-100;
$blue-20: $primary-200;
$blue-40: $primary-400;
$blue-80: $primary-500;
$blue-100: $primary-600;
$blue-120: $primary-700;
$blue-150: $primary-900;
$blue-170: $primary-dark;

$orange-10: hsla(28, 85%, 89%, 1);
$orange-50: hsla(33, 100%, 50%, 1);
$orange-100: hsla(25, 66%, 35%, 1);

$gray-2: $neutral-00;
$gray-5: $neutral-00;
$gray-7: $neutral-100;
$gray-10: $neutral-200;
$gray-20: $neutral-300;
$gray-40: $neutral-400;
$gray-60: $neutral-500;
$gray-80: $neutral-600;
$gray-100: $neutral-700;

$black: hsla(210, 11%, 15%, 1);
$true-black: hsla(0, 0%, 0%, 1);

$yellow-20: hsla(42, 100%, 93%, 1);
$yellow-60: hsla(45, 58%, 66%, 1);
$yellow-100: hsla(42, 99%, 65%, 1);
$yellow-160: hsla(41, 31%, 48%, 1);

$enterprise-80: hsla(209, 40%, 28%, 1);
$enterprise-100: hsla(209, 38%, 23%, 1);
$enterprise-120: hsla(208, 40%, 18%, 1);

$enterprise-gradient: linear-gradient(to left, $enterprise-120, $enterprise-100);

$red-5: hsla(2, 81%, 94%, 1);
$red-10: hsla(0, 73%, 73%, 1);
$red-15: hsla(0, 50%, 60%, 1);
$red-20: hsla(5, 83%, 63%, 1);
$red-50: hsla(3, 83%, 55%, 1);
$red-100: hsla(0, 63%, 20%, 1);

$green-5: hsla(137, 64%, 95%, 1);
$green-10: hsla(140, 60%, 85%, 1);
$green-20: hsla(140, 60%, 77%, 1);
$green-40: hsla(150, 70%, 45%, 1);
$green-60: hsla(150, 75%, 35%, 1);
$green-100: hsla(150, 80%, 24%, 1);

$dim-green-20: hsla(110, 19%, 59%, 1);

$secondary-00: hsla(178, 60%, 70%, 0.4);
$secondary-200: hsla(178, 60%, 75%, 1);
$secondary-400: hsla(178, 93%, 35%, 1);
$secondary-600: hsla(178, 82%, 28%, 1);

$violet-200: hsla(234, 62%, 87%, 1);
$violet-400: hsla(234, 62%, 62%, 1);
$sea-200: hsla(198, 59%, 78%, 1);
$sea-400: hsla(198, 93%, 39%, 1);

$robins-egg-blue: $secondary-400;

$tiffany-blue: hsla(178, 93%, 39%, 0.1);
$light-lapis-lazuli: hsla(198, 93%, 39%, 0.1);
$lapis-lazuli: hsla(198, 66%, 39%, 1);
$space-cadet: hsla(225, 72%, 20%, 0.6);
$teal: hsla(174, 77%, 50%, 1);

$cubic: cubic-bezier(0.165, 0.84, 0.44, 1);
$bg-cubic: background-color 0.1s $cubic;
$color-cubic: color 0.1s $cubic;

$z-important: 200;
$z-image-viewer: 93;
$z-dialog-dropdown: 92;
// $z-dialog: 91;
// $z-dialog-mask: 90;

$z-dialog: 13;
$z-dialog-mask: 12;

$z-portfolio-viewer: 13;
$z-portfolio-viewer-mobile: 12;

$z-chatbox: 10;

$z-header: 10;
$z-header-mobile: 11;
// $z-subnav: 9;
$z-viewer: 8;
$z-viewer-mask: 7;

// $z-headless-dialog: 20; // Note - this was aligned to other modal(evergreen) that we used so it can show on one another
$z-tree: 7;

// this should be equal or higher than the header to show on top of the filter section in frontend
// but should still be lower than the dialog
$z-nav: 11;
// $z-nav-mobile: 11;

$z-subnav: 4;

$z-button: 5;
$z-menu: 4;
$z-mask: 3;
$z-base: 1;
$z-label: 1;

$z-background: 0;
$z-behind: -1;
$z-underground: -1;

$z-resize: 3; // adjusted to z-index lower than the modal/dialog
$z-resize-mask: 4;

$sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$mono: 'Courier', 'Courier New', Monaco, monospace;

/* Shadow Update */

$shadow-10: 0 1px 3px hsla(0, 0%, 0%, 0.12), 0 1px 2px hsla(0, 0%, 0%, 0.24);
$shadow-20: 0 3px 6px hsla(0, 0%, 0%, 0.15), 0 2px 4px hsla(0, 0%, 0%, 0.12);
$shadow-30: 0 10px 20px hsla(0, 0%, 0%, 0.15), 0 3px 6px hsla(0, 0%, 0%, 0.1);
$shadow-40: 0 15px 25px hsla(0, 0%, 0%, 0.15), 0 5px 10px hsla(0, 0%, 0%, 0.05);
$shadow-50: 0 20px 40px hsla(0, 0%, 0%, 0.2);

/* End Shadow Update */
$dark-shadow-30: 0 10px 20px 0 hsla(0, 0%, 0%, 0.15), 0 3px 6px hsla(0, 0%, 0%, 0.1);
$dialog-shadow: 0 13px 25px -7px hsla(207, 10%, 21%, 0.25);

$portfolio-item-shadow: 0 1px 3px hsla(0, 0%, 0%, 0.1);
$card-shadow: 0 2px 7px hsla(232, 89%, 15%, 0.2);
$card-shadow-hover: 0 0 25px hsla(232, 89%, 15%, 0.3);
$shadow-on: 0 10px 10px 0 hsla(217, 69%, 42%, 0.12), 0 0 1px 0 hsla(217, 62%, 43%, 0.25);

// Old shadows
$hover-shadow: 0 2px 30px hsla(207, 13%, 56%, 0.5);
$big-shadow: 0 5px 35px hsla(232, 89%, 15%, 0.3);
$basic-shadow-alt: 0 5px 25px 0 hsla(232, 89%, 15%, 0.2);

$content-width: 1200px;
$settings-width: 1000px;

// Media Queries

$xs-min-screen: '(min-width: 485px)';
$sx-min-screen: '(min-width: 700px)';
$sm-min-screen: '(min-width: 960px)';
$md-min-screen: '(min-width: 1280px)';

$xs-screen: '(max-width: 485px)';
$sx-screen: '(max-width: 700px)';
$ss-screen: '(max-width: 768px)'; // Login only
$sm-screen: '(max-width: 960px)';
$md-screen: '(max-width: 1280px)';
$mdl-screen: '(max-width: 1600px)';
$lg-screen: '(max-width: 1920px)';

$short-screen: '(max-height: 550px)';
$short-min-screen: '(min-height: 551px)';

$card-border: 1px solid $gray-20;
$card-border-radius: 8px;
$inter-card-border: 1px solid $gray-7;
$true-black: hsla(0, 0%, 0%, 1);

@mixin for-size($size, $extra: 0) {
  @if $size==small-phone-only {
    @media only screen and (max-width: 320px + $extra) {
      @content;
    }
  } @else if $size==medium-phone-only {
    @media only screen and (max-width: 380px + $extra) {
      @content;
    }
  } @else if $size==phone-only {
    @media only screen and (max-width: 599px + $extra) {
      @content;
    }
  } @else if $size==tablet-portrait-up {
    @media only screen and (min-width: 600px + $extra) {
      @content;
    }
  } @else if $size==tablet-portrait-down {
    @media only screen and (max-width: 600px + $extra) {
      @content;
    }
  } @else if $size==tablet-landscape-up {
    @media only screen and (min-width: 900px + $extra) {
      @content;
    }
  } @else if $size==tablet-landscape-down {
    @media only screen and (max-width: 900px + $extra) {
      @content;
    }
  } @else if $size==desktop-up {
    @media only screen and (min-width: 1200px + $extra) {
      @content;
    }
  } @else if $size==desktop-down {
    @media only screen and (max-width: 1200px + $extra) {
      @content;
    }
  } @else if $size==big-desktop-down {
    @media only screen and (max-width: 1800px + $extra) {
      @content;
    }
  } @else if $size==big-desktop-up {
    @media only screen and (min-width: 1800px + $extra) {
      @content;
    }
  }
}

$phone-upper-boundary: 600px;
$tablet-portrait-upper-boundary: 900px;
$tablet-landscape-upper-boundary: 1200px;
$desktop-upper-boundary: 1800px;

$publicsans: 'Public Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
  sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

// $cerebri: 'Cerebri Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
//   sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

$cerebri: 'Adelle Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

$archia: 'Archia', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

$sans-serif: 'SF UI Text', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial,
  sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

// @mixin ie-only {
//   // media query that targets ie 10+
//   @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
//     @content;
//   }
// }

@mixin icon-hover {
  transition: 0.2s background $cubic;
  border-radius: 3px;
  &:hover {
    background-color: $gray-10;
  }

  &:active {
    background-color: $gray-20;
  }
}

@mixin icon-hover-dark {
  transition: 0.2s background $cubic;
  border-radius: 3px;
  &:hover {
    background-color: $gray-100;
  }

  &:active {
    background-color: $gray-60;
  }
}

@mixin scroll-margin-top($value: 90px) {
  // For iOS 11 - 14.4:
  scroll-snap-margin-top: $value;
  // For modern browsers:
  scroll-margin-top: $value;
  // See compatibility: https://caniuse.com/?search=scroll-margin-top
}
