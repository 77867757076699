@import '@hiredigital/ui/variables';

.navContainer {
  width: 100%;
  background-color: hsla(225, 100%, 18%, 0.8);
  backdrop-filter: blur(15px);
  position: sticky;
  top: 60px;
  z-index: $z-mask;

  @include for-size(phone-only) {
    margin-bottom: 20px;
  }
}

.nav {
  width: 100%;
  // height: 50px;
  max-width: $content-width + 40px;
  box-sizing: border-box;
  padding: 0 20px;
  margin: auto;
  display: flex;
  // align-items: space-between;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: none;

  ::-webkit-scrollbar {
    display: none;
  }
}

.navLink {
  padding: 5px 15px;
  flex-grow: 0;
  text-decoration: none;
  color: $neutral-00;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: $line-height-3;
  font-size: $size-3;
  // background: $blue-150;
  // border-bottom: 1px solid $gray-40;
  // border-top: 1px solid $gray-40;

  &:hover {
    color: $yellow-100;
    text-decoration: underline;
  }
}

.navActive {
  // background: $blue-150;
  // border-top: 1px solid $black;
  border-bottom: 2px solid $gray-2;
}

.container {
  width: 296px;
  margin-left: 30px;
  flex-shrink: 0;

  @include for-size(tablet-landscape-down) {
    width: auto;
  }
}

.box {
  margin-bottom: 30px;
}

.sticky {
  position: sticky;
  top: 90px;
}

.header {
  // border-bottom: 1px solid $gray-10;
  line-height: 1.25;
  margin: 0;
  margin-bottom: 20px;
  // margin-top: 40px;
  font-weight: $fw-1;
  font-size: $size-6;

  @include for-size(phone-only) {
    font-size: $size-6;
  }
}

.storyItem {
  display: flex;
  text-decoration: none;
  margin-bottom: 20px;

  @include for-size(phone-only) {
    &:nth-of-type(1n + 6) {
      display: none;
    }
  }
}

.storyImage {
  width: 75px;
  height: 75px;
  flex-shrink: 0;
  object-fit: cover;
}

.storyTitle {
  margin: 0px;
  margin-left: 20px;
  font-size: 18px;
  line-height: 1.4;
  display: flex;
  align-items: center;
  color: $gray-80;
  text-decoration: none;
  font-weight: $fw-2;

  &:hover {
    text-decoration: underline;
    color: $blue-100;
  }
}

.storyLink {
  margin-bottom: 14px;
  font-size: 16px;
  font-weight: $fw-2;
  display: block;
  color: $gray-80;
  text-decoration: none;

  &:hover {
    color: $blue-100;
    text-decoration: underline;
  }
}

.subscribe {
  position: sticky;
  top: 90px;
  display: block;

  @include for-size(tablet-landscape-down) {
    display: none;
  }
}

.subscribeMobile {
  display: none;

  @include for-size(tablet-landscape-down) {
    display: block;
    margin: 0 -20px;
  }
}

.postFooter {
  display: none;

  @include for-size(tablet-landscape-down) {
    display: block;
    margin: 20px;
    width: 100%;
  }
}

.postAside {
  position: absolute;
  height: 50%;
  right: -310px;
  width: 300px;
  top: 50%;

  // top: 50%;
  @media (max-width: 1400px) {
    display: none;
  }
}

$soc-top: 280px;
$soc-margin: $soc-top + 40px;

.postSocialAside {
  position: absolute;
  left: -30px;
  width: 36px;
  height: calc(100% - #{$soc-margin});
  top: $soc-top;

  @media (max-width: 900px) {
    display: none;
  }
}

.socialSticky {
  position: sticky;
  top: 90px;
}

.socialButton {
  width: 37px;
  height: 37px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  transition: transform 0.3s $cubic;
  cursor: pointer;

  &:hover {
    background-color: $black !important;
    transform: translateY(-3px);
  }
}
